.main-div{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.line-graph-cont{
    background: #19234A;
    /* opacity: 8%; */
    padding:'15px';
    margin:"25px";
    width:"50%";
    height: 230px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    margin-right: 4rem;
    border-radius: 10px;
}

.legend-cont{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 15px;
    margin-left: 15px;
}

.fixed-marg{
    margin-right: 3%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.leg-cont1{
    height: 5px;
    width: 20px;
    background-color: #C541B8;
}

.leg-cont2{
    height: 5px;
    width: 20px;
    background-color: #63C178;
}

.leg-cont3{
    height: 5px;
    width: 20px;
    background-color: #40DDF4;
}

.leg-sty{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    margin-left: 10px;
}

.line-chart-cont{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-top: 1.5rem;
}