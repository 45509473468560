.logo-sty{
    height: 40px;
    width: 72px;
    margin-top: 2rem;

}

.flex-row-center{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.flex-column-center{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.flex-column-end{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}

.flex-column-start{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
}

.logo-sty2{
    margin-top: 1rem;
    cursor: pointer;
}

.bar-menus{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    color: #ffffff;
    margin-top: 1rem;
    cursor: pointer;
}