.main-back{
    display: flex;
    flex-direction: row;
    background: radial-gradient(circle, #201D47, #17153A);
    height: fit-content;
    /* padding: 25px; */
    width: 100vw;
    overflow-y: scroll;
}

.side-div{
    width: 10%;
    position: sticky;
}

.button-div{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    /* margin-right: 30px; */
}

.server-btn{
    width: 200px;
    height: 50px;
    border: 1px solid #39C0B8;
    border-radius: 10px;
    padding: 15px;
    margin-right: 5%;
    background-color: transparent;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
}

.content-sty{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #ffffff;
}

.dashboard-div{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.fixed-marg{
    margin-top: 2.5rem;
}